.nav_con {
  background-color: #010326;
  width: 100vw;
  height: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .nav_inner_con {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid green;
    div {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
    }
    ul {
      display: flex;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #ffffff;
        cursor: pointer;
        margin: 0px;
        padding: 0px 0px 0px 40px;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
      .connec_wallet {
        background: linear-gradient(to right, #005dc8, #17a2b8);
        height: 45px;
        width: 150px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
        border-radius: 10px;
        font-weight: 600;
      }
    }
    .menu_btn {
      // border: 1px solid green;
      height: 20px;
      width: 20px;
      cursor: pointer;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      span {
        width: 100%;
        border: 2px solid #fff;
        border-radius: 2px;
      }
    }
  }
}

@media screen and (max-width: 946px) {
  .nav_con {
    height: 80px !important;
    .nav_inner_con {
      ul {
        display: none !important;
      }
      .menu_btn {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .nav_con {
    .nav_inner_con {
      div {
        img {
          width: 150px;
        }
      }
      ul {
        display: none !important;
      }
    }
  }
}
