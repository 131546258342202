.select_con {
  background: rgba(26, 24, 66, 0.51);
  border: 1px solid rgba(60, 58, 113, 0.67);
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  // margin-bottom: 30px;
  cursor: pointer;
  z-index: 99999;

  .select_inner_con {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: rgba(158, 158, 158, 0.56);

    h6 {
      margin: 0px;
      padding-left: 15px;
    }
    img {
      justify-self: flex-end;
      padding-right: 15px;
      height: 8px;
    }
  }
  .list_con {
    background: #010326;
    border: 1px solid rgb(60, 58, 113);
    box-sizing: border-box;
    border-radius: 6px;
    z-index: 999999;
    height: 100px;
    overflow: scroll;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: rgba(158, 158, 158, 0.56);
      margin: 0px;
      padding: 5px 15px;
    }
    :hover {
      background-color: rgba(99, 100, 99, 0.479);
    }
  }
}
