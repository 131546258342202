.hero_con {
  background-color: #010326;
  // height: 80vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 80px;
  .hero_inner_con {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hero_left {
      span {
        display: flex;
        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: -0.02em;
          background: linear-gradient(270deg, #faac59 0%, #fff798 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          margin: 0px;
          margin-right: 5px;
        }
      }
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        letter-spacing: -0.02em;
        color: #f5f5f5;
        margin: 0px;
      }
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #f5f5f5;
      }
      .timer_con {
        display: flex;
        width: 100%;
        align-items: center;
        div {
          width: 115px;
          height: 120px;
          background-color: #26284b;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 55px;
            line-height: 60px;
            letter-spacing: -0.02em;
            color: #f5f5f5;
          }
          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.02em;
            color: #f5f5f5;
            margin: 0px;
            padding: 0px;
          }
        }
      }
      .button_wrapper {
        display: flex;
        margin-top: 45px;
      }
    }
    .video_con {
      width: 616px;
      height: 350px;
      z-index: 55555;
      .video {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 946px) {
  .hero_con {
    .hero_inner_con {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // border: 1px solid green;
      .hero_left {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // border: 1px solid green;
        .timer_con {
          justify-content: center;
        }
      }
      .video_con {
        width: 80%;
        margin-top: 30px;
        .video {
          height: 500px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .hero_con {
    padding-bottom: 30px;
    .hero_inner_con {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .hero_left {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          text-align: center;
          line-height: 60px;
        }
        .timer_con {
          // border: 1px solid green;
          width: 100%;
          div {
            height: max-content;
            width: 400px;
            padding: 10px;
            h1 {
              font-size: 30px;
              line-height: unset;
            }
          }
        }
      }
      .video_con {
        width: 100%;
        margin-top: 30px;
        .video {
          height: 200px;
        }
      }
    }
  }
}
