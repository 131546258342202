.footer_con {
  width: 100vw;
  background-color: #010326;
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .footer_inner_con {
    width: 90%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 45px;
    div {
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #ffffff;
        margin: 0px;
        padding-top: 20px;
      }
    }

    ul {
      height: 100%;
      margin: 0px;

      li {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #ffffff;
        list-style: none;
        padding-bottom: 20px;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
      li:hover {
        text-decoration: underline;
      }
    }
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #ffffff;
    padding: 48px 0px 12px 0px;
  }
}

@media screen and (max-width: 824px) {
  .footer_con {
    .footer_inner_con {
      flex-direction: column;
      align-items: center;
      div {
        text-align: center;
        margin-bottom: 50px;
      }
      ul {
        padding-left: 0px;
        li {
          text-align: center;
          margin-right: 0px;
        }
      }
    }
    span {
      padding: 20px;
    }
  }
}
