.modal_con {
  height: 100vh;
  width: 100vw;
  background-color: #7774745b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  .whitelist {
    width: 428px;
    height: 600px;
    background: #010326;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    img {
      height: 30px;
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
    .stats_con {
      display: flex;
      flex-wrap: wrap;
      .stats_comp {
        width: max-content;
        height: 92px;
        background: #111336;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        margin: 10px;
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 25px;
          letter-spacing: -0.02em;
          color: #ffffff;
        }
        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 25px;
          letter-spacing: -0.02em;
          color: #ffffff;
          margin: 0px;
        }
      }
    }
    .button_wrapper {
      justify-self: flex-end;
      align-self: center;
      margin-top: 20px;
    }
  }

  .early_access {
    width: 428px;
    height: 600px;
    background: #010326;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    .early_inner_con {
      width: 80%;
      display: flex;
      flex-direction: column;
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #ffffff;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: #ffffff;
        margin-bottom: 30px;
      }
      input {
        background: rgba(26, 24, 66, 0.51);
        border: 1px solid rgba(60, 58, 113, 0.67);
        box-sizing: border-box;
        border-radius: 6px;
        height: 40px;
        width: 100%;
        margin-bottom: 30px;
        padding-left: 20px;
        outline: none;
        color: #fff;
      }
      .button_wrapper {
        justify-self: flex-end;
        align-self: center;
        margin-top: 30px;
      }
      .custom_input {
        margin-bottom: 30px;
        z-index: 33333;
        .custom_input_top {
          display: flex;
          align-items: center;
          background: rgba(26, 24, 66, 0.51);
          border: 1px solid rgba(60, 58, 113, 0.67);
          box-sizing: border-box;
          border-radius: 6px;
          padding-left: 15px;
          img {
            height: 15px;
          }
          input {
            margin-bottom: 0px;
            border: none;
            background: none;
            padding: 0px 0px 0px 10px;
            color: #fff;
          }
        }
        .custom_input_bottom {
          display: flex;
          justify-content: space-between;
          h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 21px;
            letter-spacing: -0.02em;
            color: rgba(158, 158, 158, 0.56);
            margin: 0px;
          }
        }
      }
      .error {
        margin: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #ff856a;
        margin-bottom: 30px;
      }
    }

    .close_btn {
      height: 30px;
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
  }
  .succes_con {
    width: 428px;
    height: 600px;
    background: #010326;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 138px;
      height: 117px;
    }
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      width: 80%;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      width: 80%;
    }
    .social_con {
      display: flex;
      margin-top: 20px;
      a {
        padding: 10px;
        img {
          height: 32px;
          cursor: pointer;
          width: unset;
        }
      }
    }
    span {
      margin-top: 100px;
    }
  }
  .connect_wallet {
    width: 428px;
    height: 300px;
    background: #010326;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: relative;
    .close_btn {
      height: 30px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      width: unset;
    }
    img {
      width: 138px;
      height: 117px;
    }
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      width: 80%;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      width: 80%;
    }
    span {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 470px) {
  .whitelist {
    width: 100% !important;
    height: 100% !important;
    padding: 15px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    img {
      top: 30px !important;
      right: 10px !important;
    }
    p {
      text-align: center !important;
    }
    h5 {
      margin: 10px;
    }
    .stats_con {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
      justify-items: center !important;
      gap: 1rem;
      padding: 0px !important;
      .stats_comp {
        width: 100% !important;
        height: 100% !important;
        padding: 0px 0px !important;
        margin: 0px 0px 80px 0px;
        h2 {
          text-align: center;
        }
        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 25px;
          letter-spacing: -0.02em;
          color: #ffffff;
          margin: 0px;
        }
      }
    }
    .button_wrapper {
      margin-top: 50px !important;
    }
  }
  .early_access {
    width: 100% !important;
    height: 100% !important;
    padding: 15px !important;
    img {
      top: 30px !important;
      right: 10px !important;
    }
    .early_inner_con {
      width: 90% !important;
    }
  }
  .succes_con {
    width: 100% !important;
    height: 100% !important;
  }
  .connect_wallet {
    height: 100% !important;
    .close_btn {
      top: 60px !important;
      right: 20px;
    }
  }
}
