.button_con {
  height: 50px;
  width: 221px;
  background: linear-gradient(to right, #005dc8, #17a2b8);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
  margin-right: 12px;
  .button_inner_con {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #010326;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #f5f5f5;
  }
}

@media screen and (max-width: 500px) {
  .button_con {
    width: 150px;
    .button_inner_con {
      font-size: 14px;
    }
  }
}
