.modal_con {
  height: 100vh;
  width: 100vw;
  background-color: #7774745b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;

  .menu_con {
    width: 428px;
    height: 600px;
    background: #010326;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    img {
      width: 25px;
    }
    div {
      img {
        margin-top: 20px;
        width: 150px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      height: max-content;
      width: 100%;
      display: flex;
      margin: 20px 0px 0px;
      padding: 0px;
      li {
        list-style: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #ffffff;
        cursor: pointer;
        // margin: 20px 15px;
        padding: 0px 0px 0px 22px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.288);
        height: 60px;
        a {
          text-decoration: none;
          color: #fff;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      .connec_wallet {
        background: linear-gradient(to right, #005dc8, #17a2b8);
        height: 45px;
        width: 150px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
        border-radius: 10px;
        font-weight: 600;
        align-self: center;
        margin-top: 30px;
      }
    }

    .close_btn {
      height: 30px;
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 470px) {
  .menu_con {
    width: 100% !important;
    height: 100% !important;
    padding: 20px !important;
    img {
      top: 40px !important;
      right: 20px !important;
    }
    .early_inner_con {
      width: 90% !important;
    }
  }
}

@media screen and (min-width: 946px) {
  .modal_con {
    display: none !important;
  }
}
